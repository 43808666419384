<template >
  <div>
      <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
          <div class="app-modal__in">
              <div
                  class="app-modal__header d-flex f-between p-5 m-title-modal"
              >
                  <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                      {{
                          $t("message.new_m", {
                              m: $t("message.question"),
                          })
                      }}
                  </p>
                  <div>
                      <crm-store-update-close
                          :permission="$options.name"
                          :button_type="'store'"
                          :loading="loadingButton"
                          @c-submit="submit(true)"
                          @c-close="close()"
                      ></crm-store-update-close>
                  </div>
              </div>
          </div>
          <!-- app-modal__header end -->

          <div class="app-modal__body p-5 pb-0">
              <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
                <el-form
                ref="form"
                status-icon
                :model="form"
                :rules="rules">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.question_direction_list") }}
                      </span>
                      <el-form-item
                          prop="title"
                          class="label_mini">
                            <select-question-direction  
                              :size="'small'"
                              :class="mode ? 'input__day' : 'input__night'"
                              :multiple="true"
                              :id="form.question_direction_ids"
                              v-model="form.question_direction_ids" >
                            </select-question-direction>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.question_category_list") }}
                      </span>
                      <el-form-item
                          prop="title"
                          class="label_mini">
                            <select-question-categories
                              :size="'small'"
                              :class="mode ? 'input__day' : 'input__night'"
                              :multiple="true"
                              :id="form.question_category_ids"
                              v-model="form.question_category_ids" >
                            </select-question-categories>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.what_for") }}
                      </span>
                      <el-form-item prop="what_for">
                        <el-select
                          class="d-block"
                          size="small"
                          v-model="form.what_for"
                          :placeholder="$t('message.what_for')"
                          :class="mode ? 'input__day' : 'input__night'"
                        >
                          <el-option
                            :label="'Ishga qabul qilish'"
                            :value="'recruiting'"
                          >
                          </el-option>
                          <el-option
                            :label="'Bilimini oshirish'"
                            :value="'increase_knowledge'"
                          >
                          </el-option>
                          <el-option
                          :label="'Barchasi uchun'"
                          :value="'for_all'"
                        >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.question_type_list") }}
                      </span>
                      <el-form-item prop="type">
                        <el-select
                          class="d-block"
                          size="small"
                          v-model="form.type"
                          :placeholder="$t('message.type')"
                          :class="mode ? 'input__day' : 'input__night'"
                        >
                          <el-option
                            :label="'Chois'"
                            :value="'chois'"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.media_type") }}
                      </span>
                      <el-form-item prop="media_type">
                        <el-select  class="d-block" size="small"  v-model="form.media_type"  :placeholder="$t('message.media_type')"  :class="mode ? 'input__day' : 'input__night'"
                        >
                          <el-option
                            :label="'Text'"
                            :value="'text'"
                          >
                          </el-option>
                          <el-option
                            :label="'Photo'"
                            :value="'photo'"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.status") }}
                      </span>
                      <el-form-item prop="status">
                        <el-select  class="d-block" size="small"  v-model="form.status"  :placeholder="$t('message.status')"  :class="mode ? 'input__day' : 'input__night'"
                        >
                          <el-option
                            :label="$t('message.active')"
                            :value="'active'"
                          >
                          </el-option>
                          <el-option
                            :label="$t('message.inactive')"
                            :value="'inactive'"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.time")  }} ({{ $t("messageminut") }})
                      </span>
                      <el-form-item :prop="form.time">
                        <!-- <el-input
                          :placeholder="$t('message.time')"
                          v-model="form.time"
                          size="small"
                          round
                        ></el-input> -->
                        <!-- <el-input-number v-model="form.time" :min="1" :max="10" @change="handleChange" />
                         -->
                         <crm-input  :class="mode ? 'input__day' : 'input__night'"
                           :inputValue="form.time"
                           :placeholder="$t('message.time')"
                           v-model="form.time"
                           size="medium"
                           :type="'number'"
                         ></crm-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.content") }}
                      </span>
                      <el-form-item
                          prop="content"
                          class="label_mini">
                          <el-input
                              type="textarea"
                              :rows="4"
                              :placeholder="$t('message.content')"
                              :inputValue="form.content"
                              v-model="form.content"
                              class="text-area-el"
                              :class="mode ? 'input__day' : 'input__night'"
                          ></el-input>
                      </el-form-item>
                    </el-col>
                    
                    <el-col :span="24">

                      <hr>
                      <h4>Options</h4>
                      <!-- {{ form.options }} -->
                    </el-col>
                    <!-- <el-col :span="24">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("A:") }}
                      </span>
                      <el-row :gutter="20">
                        <el-col :span="18">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                            {{ $t("Text") }}
                          </span>
                          <el-form-item
                              prop="options"
                              class="label_mini">
                              <el-input
                                  type="textarea"
                                  :rows="3"
                                  :placeholder="$t('message.options')"
                                  :inputValue="form.options"
                                  v-model="form.options"
                                  class="text-area-el"
                                  :class="mode ? 'input__day' : 'input__night'"
                              ></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                            {{ $t("Point") }}
                          </span>
                          <el-form-item
                              prop="points"
                              class="label_mini">
                              <crm-input  :class="mode ? 'input__day' : 'input__night'"
                                :inputValue="form.points"
                                :placeholder="$t('message.points')"
                                v-model="form.points"
                                size="medium"
                                :type="'number'"
                              ></crm-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      
                    </el-col>
                    <el-col :span="24">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("B:") }}
                      </span>
                      <el-row :gutter="20">
                        <el-col :span="18">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                            {{ $t("Text") }}
                          </span>
                          <el-form-item
                              prop="options"
                              class="label_mini">
                              <el-input
                                  type="textarea"
                                  :rows="3"
                                  :placeholder="$t('message.options')"
                                  :inputValue="form.options"
                                  v-model="form.options"
                                  class="text-area-el"
                                  :class="mode ? 'input__day' : 'input__night'"
                              ></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                            {{ $t("Point") }}
                          </span>
                          <el-form-item
                              prop="content"
                              class="label_mini">
                              <crm-input  :class="mode ? 'input__day' : 'input__night'"
                                :inputValue="form.points"
                                :placeholder="$t('message.options')"
                                v-model="form.points"
                                size="medium"
                                :type="'number'"
                              ></crm-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      
                    </el-col>
                    <el-col :span="24">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("C:") }}
                      </span>
                      <el-row :gutter="20">
                        <el-col :span="18">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                            {{ $t("Text") }}
                          </span>
                          <el-form-item
                              prop="content"
                              class="label_mini">
                              <el-input
                                  type="textarea"
                                  :rows="3"
                                  :placeholder="$t('message.content')"
                                  :inputValue="form.content"
                                  v-model="form.content"
                                  class="text-area-el"
                                  :class="mode ? 'input__day' : 'input__night'"
                              ></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                            {{ $t("Point") }}
                          </span>
                          <el-form-item
                              prop="content"
                              class="label_mini">
                              <crm-input  :class="mode ? 'input__day' : 'input__night'"
                                :inputValue="form.pints"
                                :placeholder="$t('message.point')"
                                v-model="form.pints"
                                size="medium"
                                :type="'number'"
                              ></crm-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      
                    </el-col> -->
                    <el-col v-if="form.options&&form.options.length>0" :span="24"  v-for="(domain, index) in form.options" :key="domain.key">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("Option: "+ (index+1)) }}
                      </span>
                      <el-row :gutter="20">
                        <el-col :span="18">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                            {{ $t("Text") }}
                          </span>
                          <!-- prop="content" -->
                          <el-form-item
                            :rules="{
                              required: true, message: 'domain can not be null', trigger: 'blur'
                            }"
                                class="label_mini">
                              <el-input
                                  type="textarea"
                                  :rows="3"
                                  :placeholder="$t('message.content')"
                                  :inputValue="domain.title"
                                  v-model="domain.title"
                                  class="text-area-el"
                                  
                                  :class="mode ? 'input__day' : 'input__night'"
                              ></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                            {{ $t("Point") }}
                          </span>
                          <!-- prop="point" -->
                          <el-form-item
                              :rules="{
                                required: true, message: 'domain can not be null', trigger: 'blur'
                              }"
                              class="label_mini">
                              <crm-input  :class="mode ? 'input__day' : 'input__night'"
                                :inputValue="domain.point"
                                :placeholder="$t('message.point')"
                                v-model="domain.point"
                                size="medium"
                                :type="'number'"
                              ></crm-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      
                    <!-- </el-col>
                      <el-col :span="12">
                        <el-form-item
                        :label="'Domain' + index"
                        :key="domain.key"
                        :prop="'domains.' + index + '.value'"
                        :rules="{
                          required: true, message: 'domain can not be null', trigger: 'blur'
                        }"
                      >
                        <el-input v-model="domain.value"></el-input>
                      </el-form-item> -->
                      <!-- <el-button @click="addDomain">New domain</el-button> -->
                    </el-col>
                    <!-- end col -->
                  </el-row>
                </el-form>
              </div>
          </div>
          <!-- end app-modal__body -->
      </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
/**
* Drawer chaqirish kerak drawerni yopish uchun
*/
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";

export default {
  mixins: [form, drawer,show],
  name: "questionController",
   
  props:{
    selectedItem: {
      default: "small",
    },
     multiple: {
      type: Boolean,
    },
  },
  watch: {
    selectedItem: {
            handler: function (val) {
              if (val) {
                this.selectedItem = this.selectedItem;
              }
            },
            immediate: true,
            deep: true,
        },
    },
  data() {
      return {
        // domains: [
        //     {
        //       key: 1,
        //       title: '',
        //       id:null,
        //       point: 0
        //     },
        //     {
        //       key: 2,
        //       title: '',
        //       id:null,
        //       point: 0
        //     },
        //     {
        //       key: 3,
        //       title: '',
        //       id:null,
        //       point: 0
        //     },
        //     {
        //       key: 4,
        //       title: '',
        //       id:null,
        //       point: 0
        //     }
        //   ],
        loadingButton:false,
      };
  },
  mounted() {
    // console.log('this.selectedItem');
    // console.log(this.selectedItem);
    // this.selectedItem.options.forEach((element,index) => {
    //   console.log(index, this.domains[index]);
    //   this.domains[index].id = element.id;
    //   this.domains[index].title = element.title;
    //   this.domains[index].point = element.point;
    // });
    // console.log(this.domains);

  },
  computed: {
      ...mapGetters({
          rules: "question/rules",
          model: "question/model",
          columns: "question/columns",
          mode: "MODE"
      }),
  },
  methods: {
      ...mapActions({
          update: "question/update",
          show: "question/show",
      }),
    handleChange(value) {
      // this.selectedItem.options.forEach((element,index) => {
      //   console.log(index, this.domains[index]);
      //   this.domains[index].id = element.id;
      //   this.domains[index].title = element.title;
      //   this.domains[index].point = element.point;
      // });
    },
   submit(close = true) {
          this.$refs["form"].validate((valid) => {
            // this.form.options = this.domains;
            console.log(this.form);
              if (valid) {
                  this.loadingButton = true;
                  this.update(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                          this.form =null;
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
      resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain() {
      this.dynamicValidateForm.domains.push({
        key: Date.now(),
        value: ''
      });
    }
  },
};
</script>

